<template>
  <section class="slide">
    <h2>Custom Operators 🍭</h2>
    <pre>
    const toUpperCase = 
  (source: Observable) => {
    return new Observable((observer) => {
      return source.subscribe({
        next(x) {
          observer.next( x.toUpperCase() )
         }
       })
     }
   )}

 from('hello')
   .pipe(toUpperCase)
   .subscribe(val => l(val))
  </pre
    >
    <iframe
      src="https://codesandbox.io/embed/laughing-sound-1dl7u?fontsize=14"
      title="am-rxjs-3"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
    <!-- <a-alert
      message="Let's create"
      description="Our server return to us a big object with a lot of properties.We only need the properties that has a number value.Create a custom operator that return to us the object with number properties only."
      type="info"
      show-icon
    /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { Alert } from 'ant-design-vue'

export default {
  // components: {
  //   'a-alert': Alert
  // },
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>
