<template>
  <section class="slide">
    <h2>Proactive - Passive</h2>
    <section>
      <div class="schema">
        <span>Cart</span>
        <span class="arrow-left">👉</span>
        <span>Invoice</span>
      </div>
    </section>
    <pre>
      
     import {Invoice} from './invoice';
    class Cart {
    constructor(invoice: Invoice){ ... }
    addProduct(product) {
        ...
        this.invoice.update(product); 
        // needs to be public 😒
        ...
          }
       }
    </pre>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.schema {
  height: 45px;
}
span {
  width: 100px;
  height: 50px;
  border: 1px solid $main-color;
  color: $main-color;
  border-radius: $border-radius;
  padding: 3px;
  margin: 5px;
}
.arrow {
  &-right {
    margin-left: 50px;
    background: $main-color;
  }

  &-left {
    margin-right: 50px;
    background: $main-color;
  }
}
</style>
