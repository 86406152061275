<template>
  <section class="slide">
    <h2>Routing</h2>

    <iframe
      src="https://codesandbox.io/embed/angular-2b9n2?fontsize=14"
      title="am-rxjs-routing"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
    <h2>Forms</h2>

    <iframe
      src="https://codesandbox.io/embed/angular-dj56j?fontsize=14"
      title="am-rxjs-form"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
span {
  height: 22px;
  background: $danger-color;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: $border-radius;
}
</style>
