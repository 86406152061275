<template>
  <div class="page-wrapper">
    <div class="graphic-header">
      <svg
        width="883px"
        height="584px"
        viewBox="0 0 883 584"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient id="a" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop stop-color="#F7FAFC" offset="0%" />
            <stop stop-color="#DFEBF7" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M835.746-2.683c58.652 62.415 62.704 127.241 12.158 194.48C772.084 292.655 402.786 394.5 283.349 387.5 203.725 382.833 108.61 449-2 586V-14L835.746-2.683z"
          fill="url(#a)"
          fill-rule="evenodd"
        />
      </svg>
    </div>
    <h1 class="home-page-title">
      {{ currentSlide }} - RxJS Course - Observables Patterns
    </h1>
    <!-- <div class="controller-right">
      <img src="@/assets/right.svg" @click="nxtSlide" />
    </div>
    <div class="controller-left">
      <img src="@/assets/left.svg" @click="preSlide" />
    </div>-->
    <slide-one v-if="currentSlide === 1"></slide-one>
    <slide-two v-if="currentSlide === 2"></slide-two>
    <slide-three v-if="currentSlide === 3"></slide-three>
    <slide-four v-if="currentSlide === 4"></slide-four>
    <slide-five v-if="currentSlide === 5"></slide-five>
    <slide-six v-if="currentSlide === 6"></slide-six>
    <slide-seven v-if="currentSlide === 7"></slide-seven>
    <slide-eight v-if="currentSlide === 8"></slide-eight>
    <slide-nine v-if="currentSlide === 9"></slide-nine>
    <slide-ten v-if="currentSlide === 10"></slide-ten>
    <slide-eleven v-if="currentSlide === 11"></slide-eleven>
    <slide-twelve v-if="currentSlide === 12"></slide-twelve>
    <div class="paginator-container">
      <a-pagination :total="120" @change="changeSlide" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Pagination } from 'ant-design-vue'
import SlideOne from '@/views/rxjs-slides/SlideOne'
import SlideTwo from '@/views/rxjs-slides/SlideTwo'
import SlideThree from '@/views/rxjs-slides/SlideThree'
import SlideFour from '@/views/rxjs-slides/SlideFour'
import SlideFive from '@/views/rxjs-slides/SlideFive'
import SlideSix from '@/views/rxjs-slides/SlideSix'
import SlideSeven from '@/views/rxjs-slides/SlideSeven'
import SlideEight from '@/views/rxjs-slides/SlideEight'
import SlideNine from '@/views/rxjs-slides/SlideNine'
import SlideTen from '@/views/rxjs-slides/SlideTen'
import SlideEleven from '@/views/rxjs-slides/SlideEleven'
import SlideTwelve from '@/views/rxjs-slides/SlideTwelve'

export default {
  components: {
    SlideOne,
    SlideTwo,
    SlideThree,
    SlideFour,
    SlideFive,
    SlideSix,
    SlideSeven,
    SlideEight,
    SlideNine,
    SlideTen,
    SlideEleven,
    SlideTwelve,
    'a-pagination': Pagination
  },

  computed: mapState('rxjscourse', ['currentSlide']),
  methods: {
    ...mapMutations('rxjscourse', ['prevSlide', 'nextSlide', 'setSlide']),
    changeSlide(page) {
      this.setSlide(page)
    },
    nxtSlide() {
      if (this.currentSlide < 12) {
        this.nextSlide()
      }
    },
    preSlide() {
      if (this.currentSlide > 1) {
        this.prevSlide()
      }
    }
  },
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.currenrSlide} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.graphic-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
.slide {
  margin: 0 10px 50px 10px;
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 10px;
  position: relative;
  border-radius: $border-radius;
  box-shadow: $shadow;
  iframe {
    width: 100%;
    min-height: 50vh;
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .paginator-container {
    background: linear-gradient(
      135deg,
      adjust-hue($footer-bg, -10deg),
      $footer-bg
    );
    width: calc(100% - 20px);
    height: 41px;
    position: fixed;
    padding: 5px;
    bottom: 0px;
    z-index: 101;
    text-align: center;
    box-shadow: $shadow;
    border-radius: 30px 30px 0 0;
  }
  .controller {
    &-right {
      width: 40px;
      height: 40px;
      position: fixed;
      bottom: calc(50% - 45px);
      right: 5px;
      z-index: 101;
      cursor: pointer;
    }
    &-left {
      width: 40px;
      height: 40px;
      position: fixed;
      bottom: calc(50% - 45px);
      left: 5px;
      z-index: 101;
      cursor: pointer;
    }
  }
  .home-page-title {
    margin-top: 78px;
    text-align: center;
    z-index: 1;
  }
  section {
    box-shadow: $shadow;
    padding: 20px;
    margin-bottom: 50px;
    background: white;
  }
  button {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
    color: #d4d5d7;
    font-size: 31px;
    outline: none;
  }
}
</style>
