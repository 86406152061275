<template>
  <section class="slide">
    <h2>All about creating observables</h2>
    <pre>

     let observable$ = new Observable(observer => {
     observer.next(1);
     observer.next(2);
     observer.error(new Error('Bad'));
   })

     const subscription = observable$.subscribe(
     value => { console.log(value)},
     error => { console.log(error.message)}
   )
   </pre
    >

    <h3>Creation functions</h3>

    <ul>
      <li>of(value1, value2, value3)</li>
      <li>from(promise/itterable/observable)</li>
      <li>fromEvent(target, eventName)</li>
      <li>interval(time)</li>
      <li>timer(time)</li>
    </ul>
    <h2>HOT vs COLD observable</h2>
    <span style="background: dodgerblue">COLD - eg. Observables</span>
    <p>
      Producer is created and activated during subscription unicast => everyone
      gets their own instance observables are "cold" by default
    </p>
    <span>HOT - eg. Subject</span>
    <p>
      Producer is created and activated outside and independent of subscription
      multicast => shared reference to the producer
    </p>
    <iframe
      src="https://codesandbox.io/embed/elegant-ptolemy-rj4i5?fontsize=14"
      title="am-rxjs-1"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
    <!-- <a-alert
      message="Let's create"
      description="Create an observable that emits numbers from 0 to 9 ascending every 400ms, and another observables from a click event.If you click on the button when the emited value from array is 6 then console.log('You win') else console.log('You lose')."
      type="info"
      show-icon
    /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { Alert } from 'ant-design-vue'

export default {
  // components: {
  //   'a-alert': Alert
  // },
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
span {
  height: 22px;
  background: $danger-color;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: $border-radius;
}
</style>
