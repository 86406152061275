<template>
  <section class="slide">
    <h2>Error handling</h2>

    <iframe
      src="https://codesandbox.io/embed/upbeat-albattani-cmqnz?fontsize=14"
      title="am-rxjs-5"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>

    <!-- <a-alert
      message="Let's create"
      description="Simulate an error from a server response, retry 3 times the same request and after try to switch to another endpoint."
      type="info"
      show-icon
    /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { Alert } from 'ant-design-vue'

export default {
  //  components: {
  //   'a-alert': Alert
  // },
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>
