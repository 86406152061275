<template>
  <section class="slide">
    <h2>Operators 🍭</h2>
    <h4>Higher order observbles</h4>
    <h3>Stopping streams</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5978047/Screenshot_2019-04-03_at_16.28.48.png"
    />
    <h3>Merge map</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979234/Screenshot_2019-04-03_at_20.38.42.png"
    />
    <h3>Concat map</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979235/Screenshot_2019-04-03_at_20.39.19.png"
    />
    <h3>Switch map</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979237/Screenshot_2019-04-03_at_20.39.53.png"
    />
    <h3>Exaust map</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979241/Screenshot_2019-04-03_at_20.40.25.png"
    />
    <h3>Zip</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979258/Screenshot_2019-04-03_at_20.44.19.png"
    />
    <h3>Combine latest</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979261/Screenshot_2019-04-03_at_20.44.37.png"
    />
    <h3>Fork join</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979262/Screenshot_2019-04-03_at_20.45.05.png"
    />
    <h3>With latest from</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979266/Screenshot_2019-04-03_at_20.45.26.png"
    />

    <iframe
      src="https://codesandbox.io/embed/laughing-firefly-jqkvz?fontsize=14"
      title="am-rxjs-4"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
    <!-- <a-alert
      message="Let's create"
      description="When a user click on document, count to 10 in 10 seconds."
      type="info"
      show-icon
    /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { Alert } from 'ant-design-vue'

export default {
  // components: {
  //   'a-alert': Alert
  // },
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
span {
  height: 22px;
  background: $danger-color;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: $border-radius;
}
</style>
