<template>
  <section class="slide">
    <h2>Reactive programming and RxJS</h2>
    <ul>
      <li>ReactiveX</li>
      <li>Streams</li>
      <li>Observables</li>
      <li>Creating observables</li>
      <li>Operators</li>
      <li>Higher order observables</li>
      <li>Error-handling</li>
    </ul>
    <h2>Observables in Angular</h2>
    <ul>
      <li>EventEmitters</li>
      <li>Http</li>
      <li>Forms</li>
      <li>Router</li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>
