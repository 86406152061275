<template>
  <section class="slide">
    <h2>Listenable - Reactive</h2>
    <div class="schema">
      <span>Cart</span>
      <span class="arrow-right">🤛</span>
      <span>Invoice</span>
    </div>
    <pre>
      
     import {Cart} from './cart';
     class Invoice {
    
    constructor(cart: Cart){ ... }
    
    init() {
        ...
    this.cart.onCartChange( callback ) 🥰
       }
   }    
        ...
       }
   }
     
    </pre>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.schema {
  height: 45px;
}
span {
  width: 100px;
  height: 50px;
  border: 1px solid $main-color;
  color: $main-color;
  padding: 3px;
  margin: 5px;
  border-radius: $border-radius;
}
.arrow {
  &-right {
    margin-left: 50px;
    background: $main-color;
  }

  &-left {
    margin-right: 50px;
    background: $main-color;
  }
}
</style>
