<template>
  <section class="slide">
    <h2>So... Observables</h2>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5973188/stream.gif"
      alt="stream"
    />
    <ul>
      <li>A stream of data ( 0 or more values of any type)</li>
      <li>Pushed over any amount of time (can end, but not necessarily)</li>
      <li>Cancelable ( can be stopped from emitting a value - unsubscribed)</li>
      <li>Lazy - won't emit values until we subscribe to them</li>
      <li>Just a function that takes an observer and returns a function</li>
    </ul>
    <h2>Dealing with async in our apps:</h2>
    <ul>
      <li>Dom events</li>
      <li>Animations</li>
      <li>AJAX</li>
      <li>WebSockets</li>
      <li>Server Events</li>
    </ul>
    <h2>Ajax with callbacks</h2>
    <pre>

      getData(
        function(successResult) {
         // do something with the data
         },
        function(faliureError) {
        // handle the error
        },
           );
    </pre>
    <h2>Ajax with promises</h2>
    <pre>

  let result = http.get('api/users.json');

      result.then(function(success) {
        // handle success
       },
      function(error) {
       // handle error
       }
     )
    </pre>
    <h2><strong>Unifying</strong> callbacks, promises and event handlers</h2>
    <h2>Shape of an observable:</h2>
    <ul>
      <li>A function</li>
      <li>
        accepts an observer ( an object with `next`, `error` and `complete`
        methods on it)
      </li>
      <li>returns a cancellation function</li>
    </ul>
    <h2>Creating an Observables</h2>
    <pre>

      let observable$ = new Observable(() => {

      })
      </pre
    >
    <ul>
      <li>read-only - consumer</li>
      <li>plain function</li>
      <li>exposes 3 channels: next, error and complete</li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
span {
  display: inline-block;
  width: 83px;
  height: 22px;
  background: $danger-color;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: $border-radius;
}
iframe {
  width: 100%;
  min-height: 50vh;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}
</style>
