<template>
  <section class="slide">
    <h2>Reactive programming and RxJS</h2>
    <pre>
      // concert spectators

      let inMainStand = 300;
      let inVipStand = 50;

      // code...

      let total = inMainStand + inVipStand; 
      // 350

      // code...

      inMainStand += 60;

      console.log(total); // total = ?

      // what we need to do... 

      total = inMainStand + inVipStand; 
      // 410
     
    </pre>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>
