<template>
  <section class="slide">
    <h2>Passive - Reactive</h2>
    <h3>
      Remote setters and updates
      <strong>VS</strong>
      Events, observation and self updates
    </h3>
    <section>
      <div class="piles">
        <span>SYNC</span>
        <span>ASYNC</span>
      </div>
      <div class="piles">
        <span>Function</span>
        <span>Promise</span>
      </div>

      <div class="piles">
        <span>Generator</span>
        <span>Observable</span>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
span {
  display: inline-block;
  width: 97px;
  height: 31px;
  background: $danger-color;
  color: white;
  padding: 5px;
  margin: 10px;
  border-radius: $border-radius;
}
</style>
