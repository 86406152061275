<template>
  <section class="slide">
    <h2>Operators 🍭</h2>
    <pre>

    map([🐄, 🥔, 🌽, 🐔], cook) => [🍔, 🍟, 🍿, 🍗]

    filter([🐄, 🥔, 🌽, 🐔], isVegetarian) => [🍟, 🍿]

    reduce([🐄, 🥔, 🌽, 🐔], eat) => [💩]

  </pre
    >

    <h3>Map</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5977725/Screenshot_2019-04-03_at_15.23.58.png"
    />
    <h3>Filter</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5977727/Screenshot_2019-04-03_at_15.25.14.png"
    />

    <h3>Scan</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5977728/Screenshot_2019-04-03_at_15.26.05.png"
    />
    <h3>Reduce</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5977793/Screenshot_2019-04-03_at_15.39.44.png"
    />
    <h3>Tap</h3>
    <img
      src="https://s3.amazonaws.com/media-p.slid.es/uploads/248920/images/5979084/Screenshot_2019-04-03_at_20.07.43.png"
    />
    <iframe
      src="https://codesandbox.io/embed/ecstatic-field-s9xef?fontsize=14"
      title="am-rxjs-2"
      allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media"
      style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;"
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    ></iframe>
    <!-- <a-alert
      message="Let's create"
      description="Recreate the previous game with operators. Make the range from 0 to 100 and crate some sweet spots where you can click to won. Make also a score functionality."
      type="info"
      show-icon
    /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { Alert } from 'ant-design-vue'

export default {
  // components: {
  //   'a-alert': Alert
  // },
  computed: mapState('app', ['appTitle']),
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>
