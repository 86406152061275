<template>
  <div class="page-wrapper">
    <div class="experiments-content">
      <h2>Experimets</h2>
      <section
        class="experiment"
        style="--optionBackground:url(https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);"
      >
        <div class="shadow"></div>
        <div class="label">
          <div class="icon">
            <i class="far fa-pause-circle"></i>
          </div>
          <div class="info">
            <div class="main">Audio API Experiment</div>
            <div class="sub">Usege of audio api and sound distorsions</div>
          </div>
        </div>
      </section>
      <section
        class="experiment"
        style="--optionBackground:url(https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);"
      >
        <div class="shadow"></div>
        <div class="label">
          <div class="icon">
            <i class="far fa-pause-circle"></i>
          </div>
          <div class="info">
            <div class="main">Audio API Experiment</div>
            <div class="sub">Usege of audio api and sound distorsions</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.currenrSlide} home page`,
          id: 'desc'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
$optionDefaultColours: #ed5565;
h2 {
  font-size: 50px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.experiments-content {
  width: 100vw;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  padding-top: 79px;
  .experiment {
    width: 100%;
    max-width: 600px;
    height: 400px;
    margin: 20px;
    position: relative;
    overflow: hidden;
    background: var(--optionBackground, var(--defaultBackground, #e6e9ed));
    background-size: auto 120%;
    background-position: center;
    cursor: pointer;
    border-radius: $border-radius;
    background-size: auto 100%;
    .shadow {
      box-shadow: inset 0 -120px 120px -120px black,
        inset 0 -120px 120px -100px black;
    }
    .label {
      bottom: 20px;
      left: 20px;
      .info > div {
        left: 0px;
        opacity: 1;
      }
    }
    .shadow {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;

      height: 120px;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }
    .label {
      display: flex;
      position: absolute;
      right: 0px;

      height: 40px;
      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 100%;

        background-color: white;
        color: var(--defaultBackground);
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        color: white;
        white-space: pre;
        > div {
          position: relative;
          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95),
            opacity 0.5s ease-out;
        }
        .main {
          font-weight: bold;
          font-size: 1.2rem;
        }
        .sub {
          transition-delay: 0.1s;
        }
      }
    }
  }
}
</style>
